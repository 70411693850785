import { default as authdjl0VO0QO1Meta } from "/opt/buildhome/repo/pages/auth.vue?macro=true";
import { default as conversation4iOzM3fTdtMeta } from "/opt/buildhome/repo/pages/conversation.vue?macro=true";
import { default as dashboard6cxkiptQlsMeta } from "/opt/buildhome/repo/pages/dashboard.vue?macro=true";
import { default as home4pMUrBFKiHMeta } from "/opt/buildhome/repo/pages/home.vue?macro=true";
import { default as indexniDiYCWjuTMeta } from "/opt/buildhome/repo/pages/index.vue?macro=true";
import { default as settings4cDYwTsA31Meta } from "/opt/buildhome/repo/pages/settings.vue?macro=true";
import { default as subtitle_45editorSBIcdyN7kbMeta } from "/opt/buildhome/repo/pages/subtitle-editor.vue?macro=true";
import { default as transcriptions8O1lyXojGZMeta } from "/opt/buildhome/repo/pages/transcriptions.vue?macro=true";
export default [
  {
    name: "auth",
    path: "/auth",
    meta: authdjl0VO0QO1Meta || {},
    component: () => import("/opt/buildhome/repo/pages/auth.vue")
  },
  {
    name: "conversation",
    path: "/conversation",
    component: () => import("/opt/buildhome/repo/pages/conversation.vue")
  },
  {
    name: "dashboard",
    path: "/dashboard",
    component: () => import("/opt/buildhome/repo/pages/dashboard.vue")
  },
  {
    name: "home",
    path: "/home",
    meta: home4pMUrBFKiHMeta || {},
    component: () => import("/opt/buildhome/repo/pages/home.vue")
  },
  {
    name: "index",
    path: "/",
    meta: indexniDiYCWjuTMeta || {},
    component: () => import("/opt/buildhome/repo/pages/index.vue")
  },
  {
    name: "settings",
    path: "/settings",
    component: () => import("/opt/buildhome/repo/pages/settings.vue")
  },
  {
    name: "subtitle-editor",
    path: "/subtitle-editor",
    meta: subtitle_45editorSBIcdyN7kbMeta || {},
    component: () => import("/opt/buildhome/repo/pages/subtitle-editor.vue")
  },
  {
    name: "transcriptions",
    path: "/transcriptions",
    meta: transcriptions8O1lyXojGZMeta || {},
    component: () => import("/opt/buildhome/repo/pages/transcriptions.vue")
  }
]